<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0"
      >
        <v-row>
          <v-col cols="12" md="10">
            <PageHeader :title="title" />
          </v-col>
          <v-col cols="12" md="2" align="end" class="mt-3">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="to-right"
                  v-on="on"
                  icon
                  v-bind="attrs"
                  @click="goToConfigOrdenesPago()"
                  ><v-icon size="30px">{{ settingsIcon }}</v-icon>
                </v-btn>
              </template>
              <span>Acceder a la configuración de órdenes de pago</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container>
            <v-row class="pr-4">
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="pt-1"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <!-- Empresa -->
                  <v-col cols="12" md="4" sm="6" class="py-0">
                    <v-autocomplete
                      ref="empresa-autocomplete"
                      v-model="empresaSelected"
                      :items="empresas"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                      autocomplete="off"
                      label="Empresa"
                      hint="Nombre"
                      persistent-hint
                      @change="empresaSelectedChange()"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Proveedores -->
                  <v-col cols="12" md="4" sm="6" class="py-0">
                    <v-autocomplete
                      ref="proveedores-autocomplete"
                      v-model="proveedorSelected"
                      :items="proveedores"
                      :search-input.sync="proveedorAutocompleteInput"
                      item-text="value1"
                      item-value="id"
                      :filter="customFilterProveedores"
                      outlined
                      clearable
                      return-object
                      dense
                      autocomplete="off"
                      label="Proveedor"
                      hint="Nombre o código"
                      persistent-hint
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value1
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >Código: {{ item.value2 }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="proveedoresLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- Tipo de valor -->
                  <v-col cols="4" md="4" sm="6" class="py-0">
                    <v-autocomplete
                      v-model="tipoValorSelected"
                      :items="tiposValor"
                      item-text="value"
                      item-value="id"
                      outlined
                      return-object
                      clearable
                      dense
                      autocomplete="off"
                      label="Tipo de valor"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Tipo de fecha -->
                  <v-col cols="3" md="3" sm="6" class="py-0">
                    <v-autocomplete
                      v-model="tipoFechaSelected"
                      :items="tiposFechas"
                      :rules="rules.required"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      dense
                      autocomplete="off"
                      label="Tipo de fecha (*)"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Fecha desde -->
                  <v-col cols="3" md="3" sm="6" class="py-0">
                    <v-menu
                      ref="menu-fecha-desde"
                      v-model="menuFechaDesde"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Fecha desde (*)"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          @keyup.enter="isFormValid && applyFilters"
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          @change="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="fechaDesdeSelected = formatDate(fechaDesde)"
                        @input="menuFechaDesde = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Fecha hasta -->
                  <v-col cols="3" md="3" sm="6" class="py-0">
                    <v-menu
                      ref="menu-fecha-hasta"
                      v-model="menuFechaHasta"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha hasta (*)"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          @keyup.enter="isFormValid && applyFilters"
                          @blur="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          @change="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="fechaHastaSelected = formatDate(fechaHasta)"
                        @input="menuFechaHasta = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Entrega de OP -->
                  <v-col cols="3" md="3" sm="6" class="py-0">
                    <v-autocomplete
                      v-model="entregaOPSelected"
                      :items="entregasOP"
                      return-object
                      :rules="rules.required"
                      item-text="value"
                      item-value="id"
                      outlined
                      dense
                      autocomplete="off"
                      label="Entrega de OP"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Entrega de recibo -->
                  <v-col cols="3" md="3" sm="6" class="py-0">
                    <v-autocomplete
                      v-model="entregaReciboSelected"
                      :items="entregasRecibo"
                      return-object
                      :rules="rules.required"
                      item-text="value"
                      item-value="id"
                      outlined
                      dense
                      autocomplete="off"
                      label="Registro de recibo"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="3"
                    sm="6"
                    md="3"
                    class="d-flex py-0 justify-content-center"
                  >
                    <v-switch
                      v-model="enviableMail"
                      id="switch1"
                      class="mt-1"
                      :disabled="this.empresaSelected == null"
                      @change="enviableMailChange()"
                    ></v-switch>
                    <label for="switch1" class="mt-2"
                      >Solo enviable por email</label
                    >
                  </v-col>
                  <v-col cols="1" class="py-2">
                    <v-tooltip right max-width="30%">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" v-bind="attrs" v-on="on">
                          {{ infoIcon }}
                        </v-icon>
                      </template>
                      <strong> Configuración para envío por email:</strong>
                      <ul>
                        <li>Email del proveedor</li>
                        <li>Formato de email, (asunto, cuerpo, remitente)</li>
                        <li>Formato de impresión de la OP</li>
                      </ul>
                      <strong> * Solo si está seleccionada una empresa</strong>
                    </v-tooltip>
                  </v-col>
                  <v-col 
                    cols="4" 
                    md="3" 
                    class="d-flex py-0 justify-content-center" 
                    v-if="this.enviableMail && this.empresaSelected"
                  >
                    <v-switch
                      class="mt-1"
                      v-model="soloNoEnviado"
                      id="switch2"
                    ></v-switch>
                    <label for="switch2" class="mt-2">Solo no enviados</label>
                  </v-col>
                </v-row>
                <v-row justify="end">
                  <v-col class="py-0" align="left">
                    <v-subheader>(*) Datos obligatorios</v-subheader>
                  </v-col>
                  <v-col cols="12" md="4" sm="6" class="pb-0 pt-5 text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      :disabled="!isFormValid"
                      elevation="2"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="ordenesDePago"
            item-key="idConsultaOp"
            class="elevation-1"
            :search="search"
            :loading="isLoading"
            v-model="registrosSeleccionados"
            @toggle-select-all="selectAllToggle"
            show-select
          >
            <template
              v-slot:[`item.data-table-select`]="{ isSelected, select }"
            >
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-simple-checkbox
                    :value="isSelected"
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    @input="select($event)"
                    :ripple="false"
                  ></v-simple-checkbox>
                </template>
                <span>Seleccionar registro</span>
              </v-tooltip>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="8" class="mt-3">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="2"
                    :md="showHelp ? 2 : 2"
                    sm="2"
                    class="mt-3"
                    v-if="tipoValorSelected"
                  >
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      disabled
                      filled
                      readonly
                      class="theme--light.v-input input right-input"
                      label="Total tipo de valor"
                      v-model="totalTipoValor"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" :md="showHelp ? 2 : 2" sm="2" class="mt-3">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      disabled
                      filled
                      readonly
                      class="theme--light.v-input input right-input"
                      label="Total de OP"
                      v-model="totalOP"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canSee">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="
                      seeComprobante(item.movProvId, item.proveedor.email)
                    "
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver orden de pago</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
        <v-row>
          <v-col cols="5" class="to-right pr-0">
            <v-btn
              v-if="
                tipoValorSelected != null &&
                ordenesDePago.length > 0 &&
                canExportArchivoTransferencia &&
                existExportArchivoTransferencia
              "
              color="primary"
              :disabled="registrosSeleccionados.length === 0"
              @click="toggleModalExportarArchivoTransferencia()"
              class="mt-2 py-4 to-right"
              align="end"
              small
            >
              Exportar archivo de transferencias
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-tooltip bottom v-if="canGoToConsultaOP">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="mt-2 py-4 to-right"
                  align="end"
                  v-on="on"
                  v-bind="attrs"
                  @click="goToConsultaProcesosMasivosOP()"
                  small
                >
                  Procesos masivos de email
                </v-btn>
              </template>
              <span
                >Acceder a la consulta de procesos masivos de email de órdenes
                de pago</span
              >
            </v-tooltip>
          </v-col>
          <!-- Exportar a Excel -->
          <v-col cols="2">
            <v-tooltip top max-width="35%">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  color="primary"
                  class="mt-2 py-4 to-right fontsize"
                  align="end"
                  v-bind="attrs"
                  outlined
                  small
                  :disabled="ordenesDePago.length === 0"
                  @click="exportExcel"
                >
                  Exportar detalle
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="primary"
              class="mt-2 mr-2 py-4 to-right fontsize"
              align="end"
              small
              @click="enviarMailMasivo()"
              :disabled="
                registrosSeleccionados.length == 0 ||
                enviablesEmailFiltrados === false
              "
            >
              Enviar por email
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-5 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="modalExportarExcel"
      v-model="modalExportarExcel"
      max-width="60%"
      persistent
    >
      <v-card>
        <v-container>
          <v-card-title>
            <v-col cols="12" md="12">
              <span class="pl-1 primary--text"
                >Se generará un archivo de transferencia con el formato
                configurado.</span
              >
            </v-col>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="to-right"
              text
              @click="toggleModalExportarArchivoTransferencia()"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              class="to-right"
              text
              :loading="exportExcelBtn"
              @click="exportArchivoTransferencia()"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="openModalEnviarOrdenes"
      v-model="openModalEnviarOrdenes"
      max-width="40%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EnviarOrdenesPagoEmail
        @closeAndReload="closeAndReload"
        :registrosSeleccionados="this.registrosSeleccionados"
      ></EnviarOrdenesPagoEmail>
    </v-dialog>
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import FiltersSelected from "@/components/shared/FiltersSelected";
import { mask } from "vue-the-mask";
import Ayuda from "@/components/shared/Ayuda.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";
import EnviarOrdenesPagoEmail from "@/components/modules/proveedores/ordenesPago/EnviarOrdenesPagoEmail"

export default {
  name: "OrdenesDePago",
  components: {
    PageHeader,
    FiltersSelected,
    Ayuda,
    EnviarOrdenesPagoEmail
  },
  directives: { mask },
  data: (vm) => ({
    optionCode: enums.webSiteOptions.ORDENES_DE_PAGO,
    seeIcon: enums.icons.SEE,
    infoIcon: enums.icons.SNB_INFO,
    registrosSeleccionados: [],
    enviableMail: false,
    soloNoEnviado: false,
    enviablesEmailFiltrados: false,
    showHelp: false,
    showIcon: true,
    showExpand: false,
    empresasLoading: null,
    exportExcelBtn: false,
    empresas: [],
    modalExportarExcel: false,
    empresaSelected: null,
    tipoFechaSelected: {
      id: 2,
      value: "Fecha comprobante",
    },
    tiposFechas: [],
    menuFechaDesde: null,
    fechaDesde: null,
    fechaDesdeSelected: null,
    menuFechaHasta: null,
    fechaHasta: null,
    fechaHastaSelected: null,
    proveedorAutocompleteInput: null,
    proveedoresLoading: null,
    proveedores: [],
    proveedorSelected: null,
    tiposValor: [],
    tipoValorSelected: null,
    showDeleteModal: false,
    title: enums.titles.ORDENES_DE_PAGO,
    searchIcon: enums.icons.SEARCH,
    rules: rules,
    isLoading: false,
    search: "",
    ordenesDePago: [],
    headers: [],
    showFilters: true,
    filtersApplied: [],
    isFormValid: true,
    calendarIcon: enums.icons.CALENDAR,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    totalOP: "0.00",
    totalTipoValor: "0.00",
    allowedActions: null,
    canSee: false,
    disabledCount: 0,
    filtrosGuardados: {},
    settingsIcon: enums.icons.SETTINGS,
    canGoToConsultaOP: false,
    entregasOP: [],
    resultsExportExcel: [],
    entregaOPSelected: {
      id: 3,
      value: "Todas",
    },
    entregasRecibo: [],
    entregaReciboSelected: {
      id: 3,
      value: "Todas",
    },
    canExportArchivoTransferencia: false,
    existExportFormatoTransferencia: false,
    csvJsonCompleto: [],
    csvJsonToExport: [],
    existExportArchivoTransferencia: false,
    openModalEnviarOrdenes: false,
  }),
  created() {
    this.setSelects();
    this.setFecha();
  },
  watch: {
    proveedorAutocompleteInput(val) {
      if (val && val.length >= 3) {
        this.getProveedoresFilter();
      }
    },
  },
  async mounted() {
    // cargo filtros aplicados si vuelve de la pantalla "VerOrdenDePago"
    if (this.$store.state.filtersProveedores != null) {
      this.empresaSelected = this.$store.state.filtersProveedores.empresa;
      this.tipoFechaSelected = this.$store.state.filtersProveedores.tipoFecha;
      this.fechaDesde = this.$store.state.filtersProveedores.fechaDesde;
      this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
      this.fechaHasta = this.$store.state.filtersProveedores.fechaHasta;
      this.fechaHastaSelected = this.formatDate(this.fechaHasta);
      this.proveedorSelected = this.$store.state.filtersProveedores.prov;
      this.tipoValorSelected = this.$store.state.filtersProveedores.tipoValor;
      this.enviableMail = this.$store.state.filtersProveedores.envioMail;
      this.soloNoEnviado = this.$store.state.filtersProveedores.soloNoEnviado;
      this.entregaReciboSelected =
        this.$store.state.filtersProveedores.entregaRecibo;
      this.entregaOPSelected = this.$store.state.filtersProveedores.entregaOP;
      this.applyFilters();
      // limpio el state para que si cambia de pantalla no le aplique los filtros después
      // UPDATE: Comento esta linea de codigo porque dentro del metodo applyFilters hace lo mismo
      // this.$store.state.filtersProveedores = null;
    }
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      fetchEmpresasByQuery: "aportes/fetchEmpresasByQuery",
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      fetchProveedoresByQuery: "proveedores/fetchProveedoresByQuery",
      getTiposValoresModuloProveedores:
        "proveedores/getTiposValoresModuloProveedores",
      consultaOrdenesPago: "proveedores/consultaOrdenesPago",
      crearProcesoEnvioMasivoOP: "proveedores/crearProcesoEnvioMasivoOP",
      saveLogEnviosEstandarOrdenesPago:
        "proveedores/saveLogEnviosEstandarOrdenesPago",
      setAlert: "user/setAlert",
    }),
    setPermisos() {
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.VER_ORDEN_DE_PAGO:
            this.canSee = true;
            break;
          case enums.modules.adminSistema.permissions
            .CONSULTA_PROCESOS_MASIVOS_OP:
            this.canGoToConsultaOP = true;
            break;
          case enums.modules.adminSistema.permissions.EXPORTAR_ARCHIVO:
            this.canExportArchivoTransferencia = true;
            break;
        }
      });
    },
    async setSelects() {
      this.tiposFechas = [
        {
          id: 1,
          value: "Fecha contable",
        },
        {
          id: 2,
          value: "Fecha comprobante",
        },
      ];
      this.entregasOP = [
        {
          id: 1,
          value: "Pendiente de entrega",
        },
        {
          id: 2,
          value: "Entregada",
        },
        {
          id: 3,
          value: "Todas",
        },
      ];
      this.entregasRecibo = [
        {
          id: 1,
          value: "Pendiente de Reg. Recibo",
        },
        {
          id: 2,
          value: "Con recibo asociado",
        },
        {
          id: 3,
          value: "Todas",
        },
      ];
      const tiposValores = await this.getTiposValoresModuloProveedores();
      this.tiposValor = tiposValores;
      const entidadesFacturantes = await this.getEntidadesFacturantes();
      this.empresas = entidadesFacturantes;
    },
    setFecha() {
      let fecha = new Date();
      this.fechaHasta = fecha.toISOString().substring(0, 10);
      this.fechaHastaSelected = this.formatDate(this.fechaHasta);
      fecha.setMonth(fecha.getMonth() - 3);
      this.fechaDesde = fecha.toISOString().substring(0, 10);
      this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
    },
    empresaSelectedChange(){
      if(!this.empresaSelected){
        this.enviableMail = false;
        this.soloNoEnviado = false;
      }
    },
    enviableMailChange(){
      if(!this.enviableMail) this.soloNoEnviado = false
    },
    getProveedoresFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (
          this.proveedores?.some(
            (x) =>
              x.value2 == this.proveedorAutocompleteInput ||
              x.value1 == this.proveedorAutocompleteInput
          )
        ) {
          return;
        } else {
          this.proveedoresLoading = true;
          const response = await this.fetchProveedoresByQuery({
            input: this.proveedorAutocompleteInput,
          });
          this.proveedores = response;
          this.proveedoresLoading = false;
        }
      }, 1000);
    },
    customFilterProveedores(item, queryText, itemText) {
      return item.value1 || item.value2.indexOf(queryText) > -1;
    },
    customFilterEmpresas(item, queryText, itemText) {
      return (
        item.nombreCuit ||
        item.cuit.indexOf(queryText) > -1 ||
        item.value.indexOf(queryText) > -1
      );
    },
    async applyFilters() {
      this.registrosSeleccionados = [];
      this.$store.state.filtersProveedores = null;
      if (this.tipoValorSelected != null) {
        this.headers = [
          {
            text: "Fecha",
            value: "fecha",
            sortable: false,
          },
          {
            text: "Fecha contable",
            value: "fechaContable",
            sortable: false,
          },
          {
            text: "Proveedor",
            value: "proveedor.nombre",
            sortable: false,
          },
          {
            text: "Nº OP",
            value: "numeroOp",
            sortable: false,
          },
          {
            text: "Total OP",
            value: `totalOpString`,
            align: "end",
            sortable: false,
          },
          {
            text: "Total tipo de valor",
            value: `tipoValor.totalTipoValorString`,
            align: "end",
            sortable: false,
          },
          {
            text: "Fecha tipo de valor",
            value: "tipoValor.fechaTipoValor",
            align: "end",
            sortable: false,
          },
          {
            text: "CBU",
            value: "proveedor.cbu",
            sortable: false,
          },
          {
            text: "Acciones",
            value: "actions",
            sortable: false,
            align: "end",
            width: "10%",
          },
        ];
      } else {
        this.headers = [
          {
            text: "Fecha",
            value: "fecha",
            sortable: false,
          },
          {
            text: "Fecha contable",
            value: "fechaContable",
            sortable: false,
          },
          {
            text: "Proveedor",
            value: "proveedor.nombre",
            sortable: false,
          },
          {
            text: "Nº OP",
            value: "numeroOp",
            sortable: false,
          },
          {
            text: "Total OP",
            value: "totalOpString",
            align: "end",
            sortable: false,
          },
          {
            text: "CBU",
            value: "proveedor.cbu",
            sortable: false,
          },
          {
            text: "Acciones",
            value: "actions",
            sortable: false,
            align: "end",
            width: "10%",
          },
        ];
      }
      this.isLoading = true;
      this.customizeFiltersApplied();
      this.showFilters = false;
      const filters = {
        empresaId: this.empresaSelected?.id,
        tipoFecha: this.tipoFechaSelected.id,
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        provId: this.proveedorSelected?.id,
        tipoValorId: this.tipoValorSelected?.id,
        enviablesEmail: this.empresaSelected ? this.enviableMail : false,
        noEnviadosXEmail: (this.enviableMail && this.empresaSelected) ? this.soloNoEnviado : false,
        entregaOP: this.entregaOPSelected?.id,
        entregaRecibo: this.entregaReciboSelected?.id,
      };
      this.filtrosGuardados = {
        empresa: this.empresaSelected,
        tipoFecha: this.tipoFechaSelected,
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        prov: this.proveedorSelected,
        tipoValor: this.tipoValorSelected,
        envioMail: this.enviableMail,
        soloNoEnviado: this.soloNoEnviado,
        entregaOP: this.entregaOPSelected ?? {},
        entregaRecibo: this.entregaReciboSelected ?? {},
      };
      this.enviablesEmailFiltrados = this.enviableMail;
      try {
        const response = await this.consultaOrdenesPago(filters);
        this.ordenesDePago = response.ordenesPago;
        this.totalOP = response.sumaOrdenesPagoString;
        if (this.tipoValorSelected != null) {
          this.totalTipoValor = response.sumaTipoValoresString;
          this.existExportArchivoTransferencia =
            response.existExportFormatoTransferencia;
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplied = [];
      if (this.empresaSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "empresaSelected",
          label: "Empresa",
          model: this.empresaSelected.value,
        });
      }
      if (this.proveedorSelected) {
        this.filtersApplied.splice(1, 1, {
          key: "proveedorSelected",
          label: "Proveedor",
          model: this.proveedorSelected.value1,
        });
      }
      if (this.tipoValorSelected) {
        this.filtersApplied.splice(2, 1, {
          key: "tipoValorSelected",
          label: "Tipo de valor",
          model: this.tipoValorSelected.value,
        });
      }
      if (this.tipoFechaSelected) {
        this.filtersApplied.splice(3, 1, {
          key: "tipoFecha",
          label: "Tipo de fecha",
          model: this.tipoFechaSelected.value,
        });
      }
      if (this.fechaDesdeSelected) {
        this.filtersApplied.splice(4, 1, {
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.fechaDesdeSelected,
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersApplied.splice(5, 1, {
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.fechaHastaSelected,
        });
      }
      if (this.enviableMail) {
        const conEmail = "SI";
        this.filtersApplied.splice(6, 1, {
          key: "enviablesEmail",
          label: "Solo enviables por email",
          model: conEmail,
        });
      } else {
        const conEmail = "NO";
        this.filtersApplied.splice(6, 1, {
          key: "enviablesEmail",
          label: "Solo enviables por email",
          model: conEmail,
        });
      }
      if (this.soloNoEnviado && this.enviableMail) {
        this.filtersApplied.splice(7, 1, {
          key: "soloNoEnviado",
          label: "Emails no enviados",
          model: "SI",
        });
      }
      if (this.entregaOPSelected) {
        this.filtersApplied.splice(8, 1, {
          key: "entregaOP",
          label: "Entrega de OP",
          model: this.entregaOPSelected.value,
        });
      }
      if (this.entregaReciboSelected) {
        this.filtersApplied.splice(9, 1, {
          key: "entregaRecibo",
          label: "Entrega de recibo",
          model: this.entregaReciboSelected.value,
        });
      }
    },
    selectAllToggle() {
      if (this.registrosSeleccionados.length < this.ordenesDePago.length) {
        this.registrosSeleccionados = this.ordenesDePago;
      } else {
        this.registrosSeleccionados = [];
      }
    },
    enviarMailMasivo() {
      this.openModalEnviarOrdenes = true;
    },
    closeAndReload() {
      this.openModalEnviarOrdenes = false;
      this.applyFilters();
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    async exportArchivoTransferencia() {
      this.exportExcelBtn = true;
      try {
        const data = {
          mpValIds: this.registrosSeleccionados.map((x) => x.mpValId),
          tvId: this.tipoValorSelected?.id,
        };
        const response = await this.saveLogEnviosEstandarOrdenesPago(data);
        if (response.status === 200) {
          this.downloadFile(response.data);
          this.setAlert({
            type: "success",
            message: "Archivo generado con éxito.",
          });
          this.exportExcelBtn = false;
          this.toggleModalExportarArchivoTransferencia();
        }
      } catch (error) {
        this.exportExcelBtn = false;
      }
    },
    downloadFile(data) {
      const archivoBytes = new Uint8Array(
        atob(data.resultArchivo)
          .split("")
          .map((char) => char.charCodeAt(0))
      );
      const blob = new Blob([archivoBytes], {
        type: "text/plain",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = data.nombreArchivo;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    toggleModalExportarArchivoTransferencia() {
      this.modalExportarExcel = !this.modalExportarExcel;
    },
    seeComprobante(id, email) {
      this.$router.push({
        name: "VerOrdenDePago",
        params: {
          movProvId: id,
          email: email,
        },
      });
      this.$store.state.filtersProveedores = this.filtrosGuardados;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    exportExcel() {
      let result = [];
      if (this.tipoValorSelected == null) {
        this.ordenesDePago.forEach((x) =>
          result.push({
            ["Fecha"]: x.fecha,
            ["Fecha contable"]: x.fechaContable,
            ["Proveedor"]: x.proveedor?.nombre,
            ["CUIT"]: x.cuit,
            ["Nº OP"]: x.numeroOp,
            ["Total OP"]: x.totalOp,
            ["CBU"]: x.proveedor?.cbu,
          })
        );
      } else {
        this.ordenesDePago.forEach((x) =>
          result.push({
            ["Fecha"]: x.fecha,
            ["Fecha contable"]: x.fechaContable,
            ["Proveedor"]: x.proveedor?.nombre,
            ["CUIT"]: x.cuit,
            ["Nº OP"]: x.numeroOp,
            ["Total OP"]: x.totalOp,
            ["Total del tipo de valor"]: x.tipoValor?.totalTipoValor,
            ["Fecha del tipo de valor"]: x.tipoValor?.fechaTipoValor,
            ["CBU"]: x.proveedor?.cbu,
          })
        );
      }
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(formato, "Ordenes de pago");
    },
    goToConfigOrdenesPago() {
      this.$router.push({
        name: "ConfiguracionOrdenesPago",
      });
    },
    goToConsultaProcesosMasivosOP() {
      this.$router.push({
        name: "ConsultaProcesosMasivosOP",
      });
    },
  },
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
.right-input >>> input {
  text-align: right;
}
</style>
