import { render, staticRenderFns } from "./OrdenesDePago.vue?vue&type=template&id=1b79e535&scoped=true&"
import script from "./OrdenesDePago.vue?vue&type=script&lang=js&"
export * from "./OrdenesDePago.vue?vue&type=script&lang=js&"
import style0 from "./OrdenesDePago.vue?vue&type=style&index=0&id=1b79e535&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b79e535",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAutocomplete,VBtn,VCard,VCardActions,VCardTitle,VCol,VContainer,VDataTable,VDatePicker,VDialog,VExpandTransition,VForm,VIcon,VListItemContent,VListItemSubtitle,VListItemTitle,VMenu,VProgressCircular,VRow,VSimpleCheckbox,VSpacer,VSubheader,VSwitch,VTextField,VToolbar,VTooltip})
